.@{class-prefix}-landing {
  &-container {
    max-width: 1184px;
    margin: 0 auto;
    padding: 0 32px;

    @media @sm {
      padding: 0 16px;
    }
  }

  @media @sm {
    h3 {
      font-size: 18px;
      line-height: normal;
    }
    h2 {
      text-align: -webkit-center;
    }
    p {
      text-align: -webkit-center;
    }
    button {
      margin: auto;
      display: block;
    }
  }

  // Header
  header {
    .@{class-prefix}-landing-header {
      &-menu {
        .ant-menu {
          border-bottom: none;

          .ant-menu-item:after {
            visibility: hidden;
          }
        }
      }

      &-mobile-button {
        display: none;
      }
    }

    @media screen and (max-width: 767px) {
      button {
        background: none !important;
      }
    }

    @media @sm {
      .@{class-prefix}-landing-header {
        &-menu {
          display: none !important;
        }

        &-buttons {
          display: none;
        }

        &-mobile-button {
          display: block;
        }
        button {
          background: none !important;
        }
      }
    }
  }

  // Features
  &-features {
    img {
      padding-bottom: 30px;
      scale: 1.3;
    }
    button {
      box-shadow: 0 8px 16px #0554b7;
      background: linear-gradient(to right, #05cbff, #1e5aff) !important;
      height: 42px;
      line-height: 42px;
      font-size: 14px;
      border: 0;
      border-radius: 21px;
      color: #fff;
      width: 128px;
      padding: 0 15px;
      display: inline-block;
      transition: transform 0.3s, box-shadow 0.3s;
    }
    .slick-slider {
      &:before,
      &:after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        width: 180px;
        background: linear-gradient(to left, #ffffff, #ffffff00);
        z-index: 1;

        @media @sm {
          width: 50px;
        }
      }

      &:before {
        left: 0;
        right: auto;
        transform: rotate(180deg);
      }

      &:after {
        right: 0;
        left: auto;
      }
    }

    .slick-list {
      padding-left: 230px;

      @media screen and (max-width: 1370px) {
        padding-left: 150px;
      }

      @media @lg {
        padding-left: 80px;
      }

      @media @sm {
        padding-left: 24px;
      }
    }
  }

  &-banner-1 {
    padding-top: 200px;
    padding-bottom: 146px !important;

    button {
      box-shadow: 0 8px 16px #0554b7;
      background: linear-gradient(to right, #05cbff, #1e5aff) !important;
      height: 42px;
      line-height: 42px;
      font-size: 14px;
      border: 0;
      border-radius: 21px;
      color: #fff;
      width: 148px;
      padding: 0 15px;
      display: inline-block;
      transition: transform 0.3s, box-shadow 0.3s;
    }

    .@{class-prefix}-landing-container {
      max-width: 75vw;
    }

    &-circle {
      width: 612px;
      height: 612px;
      border-radius: 100%;
      margin-left: auto;
    }

    &-img {
      scale: 1.2;
      position: absolute;
      right: -140px;
      top: 65%;
      transform: translate(0px, -50%);

      img {
        max-width: 880px;
      }
    }

    @media screen and (max-width: 1450px) {
      .@{class-prefix}-landing-container {
        max-width: 83vw;
      }
    }

    @media @lg {
      &-img {
        img {
          max-width: 100%;
        }
      }
    }

    @media @md {
      .@{class-prefix}-landing-container {
        max-width: 100%;

        > .ant-row {
          justify-content: center;
        }
      }

      &-circle {
        width: 500px;
        height: 500px;
      }

      &-img {
        right: 20px;
        text-align: right;

        img {
          max-width: 90%;
        }
      }
    }

    @media @sm {
      &-circle {
        width: 300px;
        height: 300px;
      }

      h3 {
        font-size: 18px;
        line-height: normal;
      }
      p {
        text-align: -webkit-center;
      }
      button {
        margin: auto;
        display: block;
      }
    }
  }

  // Content 2
  &-content-2 {
    padding-top: 80px;

    img {
      margin-left: auto;
      margin-right: auto;
      display: block;
      width: 75%;
    }

    @media @lg {
    }

    @media @sm {
      h2 {
        text-align: -webkit-center;
      }
      p {
        text-align: -webkit-center;
      }
    }

    &-list {
      max-width: 360px;
    }

    @media @lg {
      .@{class-prefix}-landing-container {
        > .ant-row {
          justify-content: center;
        }
      }
    }
  }

  &-content-3 {
    img {
      scale: 1.2;
    }
    h4 {
      padding-left: 20px;
    }
  }

  // content-4
  &-content-4 {
    &-circle {
      width: 542px;
      height: 542px;
      border-radius: 100%;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(50%, -60%);

      @media @sm {
        width: 342px;
        height: 342px;
      }
    }

    &-item {
      min-height: 200px;

      &-twitter {
        position: absolute;
        top: 16px;
        right: 20px;
      }

      &-img {
        margin-left: -10px;
        margin-right: 10px;
      }

      @media @md {
        &-title {
          flex: 0 0 100%;
        }
      }
    }
  }

  // Footer
  &-footer {
    &-circle {
      width: 255px;
      height: 255px;
      border-radius: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      transform: translate(-50%, 50%);
    }

    @media @sm {
      &-circle {
        width: 200px;
        height: 200px;
      }

      &-copyright {
        text-align: center;

        > .ant-col {
          flex: 100%;

          .ant-row {
            justify-content: center;
          }
        }
      }
    }
  }
}

// RTL
html[dir='rtl'] {
  .@{class-prefix}-landing {
    // Hero
    &-hero {
      &-img {
        &-left {
          left: auto;
          right: 6%;

          .@{class-prefix}-landing-hero-img-emoji {
            &:nth-child(1) {
              margin-right: 40px;
              margin-left: 0px;
            }

            &:nth-child(2) {
              margin-right: 30px;
              margin-left: 0px;
            }

            &:nth-child(3) {
              margin-right: -30px;
              margin-left: 0px;
            }
          }
        }

        &-right {
          left: 6%;
          right: auto;

          .@{class-prefix}-landing-hero-img-emoji {
            &:nth-child(1) {
              margin-right: 70px;
              margin-left: 0px;
            }

            &:nth-child(2) {
              margin-right: -40px;
              margin-left: 0px;
            }

            &:nth-child(3) {
              margin-right: -220px;
              margin-left: 0px;
            }
          }
        }
      }

      @media screen and (max-width: 1450px) {
        &-img {
          &-left {
            right: 30px;
          }

          &-right {
            left: 30px;
          }
        }
      }

      @media @md {
        &-img {
          &-left {
            right: 0;
          }

          &-right {
            left: 0;
          }
        }
      }

      @media @sm {
        &-img {
          &-right {
            .@{class-prefix}-landing-hero-img-emoji {
              &:nth-child(1) {
                margin-right: 40px;
              }

              &:nth-child(3) {
                margin-right: 30px;
              }
            }
          }
        }
      }
    }

    // Content 1
    &-banner-1 {
      &-circle {
        margin-right: auto;
        margin-left: 0;
      }

      &-img {
        left: -152px;
        right: auto;
        transform: translate(0px, -50%) scale(-1, 1);
      }

      @media @md {
        &-img {
          left: -40px;
          right: auto;
          text-align: left;
        }
      }
    }

    &-content-4 {
      &-circle {
        left: 0;
        right: auto;
        transform: translate(-50%, -60%);
      }

      &-item {
        &-twitter {
          left: 20px;
          right: auto;
        }

        &-img {
          margin-right: -10px;
          margin-left: 10px;
        }
      }
    }

    // Footer
    &-footer {
      &-circle {
        right: 0;
        transform: translate(50%, 50%);
      }
    }
  }
}
