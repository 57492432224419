#Nav3_0.jzih1dpqqrg-editor_css {
  position: absolute;
  background-color: rgba(255, 255, 255, 0);
  box-shadow: none;
}
.ant-menu-item > .item-sub-item > .jzj81c9wabh-editor_css {
  float: none;
  margin: 4px 0px 0px;
}
.ant-menu > .ant-menu-item > .jzj8295azrs-editor_css {
  padding: 0;
}
#Nav3_0 .home-page > .jzjgnya1gmn-editor_css {
  width: 110px;
}
#Footer1_0 .ant-row > .ant-col > .jzl0qcpyjra-editor_css {
  width: 100px;
}
#Footer1_0 .ant-col > div > .jzl0u1bko6-editor_css {
  margin: 16px 0 0;
}
#Content13_0.l7ak6p99hq-editor_css {
}
#Feature8_0 div > div > .l7akl1qdho-editor_css {
  justify-content: center;
}
#Content11_0 .l7gcvg48k7-editor_css {
}
#Content11_0 .l7gcvg48k7-editor_css > .l7gmbt2xbqq-editor_css {
  background-image: linear-gradient(
    to right,
    rgb(5, 203, 255) 0%,
    rgb(30, 90, 255) 100%
  );
}
#Banner5_0 .l7j0xinprfu-editor_css {
  background-clip: padding-box;
}
#Banner5_0.l7gm7udjofb-editor_css {
  clear: left;
  background-image: url('https://zos.alipayobjects.com/rmsportal/gGlUMYGEIvjDOOw.jpg'),
    linear-gradient(
      to top,
      rgba(253, 253, 253, 0.3) 0%,
      rgba(193, 218, 255, 0.3) 100%
    );
  background-attachment: scroll, scroll;
  background-blend-mode: normal, normal;
  background-position: 0% 0%, 0% 0%;
  background-repeat: repeat, repeat;
  background-size: auto, auto;
  background-clip: padding-box, padding-box;
}
#Content11_0.l7j0yd72ir8-editor_css {
  background-image: url('https://zos.alipayobjects.com/rmsportal/gGlUMYGEIvjDOOw.jpg'),
    url('https://gw.alipayobjects.com/zos/rmsportal/ZsWYzLOItgeaWDSsXdZd.svg');
  background-attachment: scroll, scroll;
  background-blend-mode: normal, normal;
  background-position: 0% 0%, center bottom;
  background-repeat: repeat, no-repeat;
  background-size: auto, 100%;
  background-clip: padding-box, padding-box;
}
#Banner5_0
  .banner5-title-wrapper
  > .banner5-button-wrapper
  > .l7gm64hpdkj-editor_css {
  font-family: -apple-system, BlinkMacSystemFont, 'segoe ui', Roboto,
    'helvetica neue', Arial, 'noto sans', sans-serif, 'apple color emoji',
    'segoe ui emoji', 'segoe ui symbol', 'noto color emoji';
  background-color: rgba(0, 0, 0, 0);
  background-image: ;
  background-attachment: ;
  background-blend-mode: ;
  background-position: ;
  background-repeat: ;
  background-size: ;
  background-clip: ;
}
#Content11_0 .title-wrapper > .l7j0zsmdrv-editor_css {
  color: #fbfbfb;
}
#Banner5_0 .home-page > .banner5-title-wrapper > .l7j145jztou-editor_css {
  color: rgb(255, 255, 255);
}
#Banner5_0 .home-page > .banner5-title-wrapper > .l7j14hl5nk-editor_css {
  color: rgba(255, 255, 255, 0.55);
}
